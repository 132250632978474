import React, { useState, useEffect } from 'react'
import Skeleton from '@mui/material/Skeleton'

import { getToApi, defaultPeriod, round, euro } from '../../../functions/utilsFunctions'

let interval = null
const INTOUCH_FEE = 0.025

function BuyBilan({provider}) {
    const [loading, setLoading] = useState(true)
    const [transactions, setTransactions] = useState({ txs: [], cm: 0, ci: 0, sn: 0 })
    const [result, setResult] = useState({ btc: 0, provide: 0, fee: 0, mg: 0 })
    const [transactNumber, setTransactNumber] = useState(0)
    useEffect(() => {
        setLoading(false)
        getTransactions()
        interval = setInterval(() => {
            // console.log("get interval")
            getTransactions()
        }, 600 * 1000)
        return () => {
            // console.log("the intervale ", interval)
            clearInterval(interval)
        }
    }, [provider])


    const getTransactions = async (period = defaultPeriod) => {
        let buy = await getToApi('getbuybydate/:' + period)
        // console.log("the buy ", buy)
        setTransactions({ ...transactions, txs: buy })
        calculMarge(buy)
        setLoading(false)
    }
    const calculMarge = (txs) => {
        switch (provider) {
            case "wenguii":
                let operations=[], total_crypto = 0 ,operation_completes=[], fee=0.095, total_amount=0
                operations = txs.filter(element => element.provider === "wenguii");
                operation_completes = operations.filter(val => val.status === "complete" );
                setTransactNumber(operation_completes.length)
                if (operation_completes.length) {
                    total_amount = operation_completes.map(val => val.amount).reduce((total, elt) => total + elt);
                    total_crypto = operation_completes.map(val => val.crypto).reduce((som, elt) => som + elt);
                    fee = total_amount * fee
                    setResult({ btc: total_crypto  ,provide: total_amount, fee: fee, mg: total_amount - total_crypto - fee })
                } else setResult({ btc: 0, provide: 0, fee: 0, mg: 0 })
                // console.log("le buybilan de wenguii")
                break;
            case "intouch":
                let operats=[], total_crypt = 0 ,operation_complets=[], feese=0.095, total_amounts=0
                operats = txs.filter(element => element.provider === "intouch");
                operation_complets = operats.filter(val => val.status === "complete" );
                setTransactNumber(operation_complets.length)
                if (operation_complets.length) {
                    total_amounts = operation_complets.map(val => val.amount).reduce((total, elt) => total + elt);
                    total_crypt = operation_complets.map(val => val.crypto).reduce((som, elt) => som + elt);
                    feese = total_amounts * feese
                    setResult({ btc: total_crypt  ,provide: total_amounts, fee: feese, mg: total_amounts - total_crypt - feese })
                } else setResult({ btc: 0, provide: 0, fee: 0, mg: 0 })
                // let ctxs = [] // completd transactions table
                // txs.forEach((tx) => {
                //     if (tx.status === 'complete') ctxs.push(tx)
                // })
                // if (ctxs.length) {
                //     let btc = 0, intouch = 0, fee = 0
                //     ctxs.forEach((item) => {
                //         btc += item.crypto
                //         intouch += item.amount
                //         fee += item.amount * INTOUCH_FEE
                //     })
                //     // console.log("les montants ", btc, intouch)
                //     setResult({ btc: btc, provide: intouch, fee: fee, mg: intouch - btc - fee })
                // }
                // else setResult({ btc: 0, provide: 0, fee: 0, mg: 0 })
                break;
            case "cinetpay":
                let operationes=[], totale_crypto = 0 ,operationes_complete=[], fees=0.095, totale_amount=0
                operationes = txs.filter(element => element.provider === "cinetpay");
                operationes_complete = operationes.filter(val => val.status === "complete" );
                setTransactNumber(operationes_complete.length)
                // console.log("operat", operationes_complete, operationes_complete.length)
                if (operationes_complete.length ) {
                    totale_amount = operationes_complete.map(val => val.amount).reduce((total, elt) => total + elt);
                    totale_crypto = operationes_complete.map(val => val.crypto).reduce((som, elt) => som + elt);
                    fees = totale_amount * fees
                    setResult({ btc: totale_crypto  ,provide: totale_amount, fee: fees, mg: totale_amount - totale_crypto - fees })
                } else setResult({ btc: 0, provide: 0, fee: 0, mg: 0 })
                break;
        
            default:
                let operateur = txs.filter(val => val.status === "complete" );
                setTransactNumber(operateur.length)
                let totale_amoun = operateur.map(val => val.amount).reduce((total, elt) => total + elt);
                let totale_cryptoy = operateur.map(val => val.crypto).reduce((som, elt) => som + elt);
                let feey = totale_amount * 0.095
                setResult({ btc: totale_cryptoy  ,provide: totale_amoun, fee: feey, mg: totale_amoun - totale_cryptoy - feey })
                break;
        }
    }

    return (
      <div className="buy">
        <div className="asses">
          <>
            <h1>Bilan de la vente de crypto</h1>
            <div className="assests">
              <div className="assest">
                { !loading ? (
                  <>
                    <div
                      className="amount"
                      style={{ backgroundColor: "#cc1616" }}
                    >
                      <h2>{round(2, result.provide / euro)}&euro;</h2>
                      <h4>{result.provide} XAF</h4>
                    </div>
                    <p>D'encaissements effectues par {provider}</p>
                  </>
                ) : (
                  <Skel color="error.main" />
                )}
              </div>

              <div className="assest">
                {!loading ? (
                  <>
                    <div
                      className="amount"
                      style={{ backgroundColor: "#038cf4" }}
                    >
                      <h2>{round(2, result.fee / euro)}&euro;</h2>
                      <h4>{result.fee} XAF</h4>
                    </div>
                    <p>De frais Ipercash + {provider}</p>
                  </>
                ) : (
                  <Skel color="primary.main" />
                )}
              </div>

              <div className="assest">
                {!loading ? (
                  <>
                    <div
                      className="amount"
                      style={{ backgroundColor: "#f18e1b" }}
                    >
                      <h2>{round(2, result.btc)}&euro;</h2>
                      <h4>{Math.round(result.btc * 655.77)} XAF</h4>
                    </div>
                    <p>De cryptos envoyes</p>
                  </>
                ) : (
                  <Skel color="warning.main" />
                )}
              </div>
            </div>
            <center>
              <h3> {transactNumber} Transactions completes sur {transactions.txs.length} Transactions initiees</h3>
            </center>
            <div className="bar"></div>
            <Marge result={result} />
          </>
          {/* ) : (<Skel variant="rectangular" heigth={200} />)} */}
        </div>
      </div>
    );
}

const Marge = ({ result }) => {
    return (
        <div className="bilan">
            <div className="">La marge realisee est de : </div>
            <div className=""> {round(2, result.fee/euro)} &euro; / {result.fee} XAF </div>
        </div>
    )
}

const Skel = ({color}) => {
    return (
        <div >
            <Skeleton sx={{ bgcolor: color}} variant="circular" width={125} height={125} />
            <span className='mar'></span>
            <Skeleton variant="text"  sx={{ fontSize: '1rem' }} />
        </div>
    )
}
{/* <Skeleton animation="wave" variant={variant} height={heigth} width={width} /> */}


export default BuyBilan