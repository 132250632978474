import React, { useState, useEffect } from 'react'
import ReactLoading from 'react-loading'
import Skeleton from '@mui/material/Skeleton'

// import Period from '../shared/time/Date'

import { getToApi, defaultPeriod, round } from '../../../functions/utilsFunctions'

let interval=null

function SendBilan({provider}) {
    const [loading, setLoading]=useState(true)
    const [transactions, setTransactions]=useState({txs: [], cm:0, ci:0, sn:0})
    const [result, setResult]=useState({ mercuryo: 0, bch: 0, provide: 0, fee: 0, mg: 0 })
    const [transactNumber, setTransactNumber] = useState(0)
    useEffect(()=>{
        getTransactions()
        interval=setInterval(() => {
            // console.log("get interval")
            getTransactions()
        }, 600*1000)
        return ()=>{
            // console.log("the intervale ", interval)
            clearInterval(interval)
        }
    }, [provider])

    const getTransactions=async(period=defaultPeriod)=>{
        let send=await getToApi('getsendbydate/:'+period)
        console.log("the send ", send)
        setTransactions({...transactions, txs: send})
        calculMarge(send)
        if (send) {
            setLoading(false)
        }
    }
    
    const calculMarge=(txs)=>{

        switch (provider) {
            case "wenguii":
                let operations=[], total_crypto = 0 ,operation_completes=[], fee=655.77, total_amount=0
                operations = txs.filter(element => element.provider === "wenguii");
                operation_completes = operations.filter(val => val.status === "completed" );
                setTransactNumber(operation_completes.length)
                if (operation_completes.length) {
                    total_amount = operation_completes.map(val => val.mercuryo).reduce((total, elt) => total + elt);
                    total_crypto = operation_completes.map(val => val.bch).reduce((som, elt) => som + elt);
                    fee = 655.77 * operation_completes.length
                    setResult({ bch: total_crypto  ,provide: total_amount, fee: fee, mg: total_crypto * 655.77  - total_amount - fee })
                } else setResult({ bch: 0, provide: 0, fee: 0, mg: 0 })
                // console.log("le buybilan de wenguii")
                break;
            case "intouch":
                let operats=[], total_crypt = 0 ,operation_complets=[], feese=0.095, total_amounts=0
                operats = txs.filter(element => element.provider === "intouch");
                operation_complets = operats.filter(val => val.status === "completed" );
                console.log("operation nmber", operation_complets)
                setTransactNumber(operation_complets.length)
                if (operation_complets.length) {
                    total_amounts = operation_complets.map(val => val.mercuryo).reduce((total, elt) => total + elt);
                    total_crypt = operation_complets.map(val => val.bch).reduce((som, elt) => som + elt);
                    feese = total_amounts * feese
                    setResult({ bch: total_crypt  ,provide: total_amounts, fee: feese, mg: total_crypt * 655.77  - total_amounts - feese })
                } else setResult({ bch: 0, provide: 0, fee: 0, mg: 0 })
                // let ctxs = [] // completd transactions table
                // txs.forEach((tx) => {
                //     if (tx.status === 'complete') ctxs.push(tx)
                // })
                // if (ctxs.length) {
                //     let bch = 0, intouch = 0, fee = 0
                //     ctxs.forEach((item) => {
                //         bch += item.crypto
                //         intouch += item.amount
                //         fee += item.amount * INTOUCH_FEE
                //     })
                //     // console.log("les montants ", bch, intouch)
                //     setResult({ bch: bch, provide: intouch, fee: fee, mg: intouch - bch - fee })
                // }
                // else setResult({ bch: 0, provide: 0, fee: 0, mg: 0 })
                break;
            case "cinetpay":
                let operationes=[], totale_crypto = 0 ,operationes_complete=[], fees=0.095, totale_amount=0
                operationes = txs.filter(element => element.provider === "cinetpay");
                operationes_complete = operationes.filter(val => val.status === "completed" );
                setTransactNumber(operationes_complete.length)
                // console.log("operat", operationes_complete, operationes_complete.length)
                if (operationes_complete.length ) {
                    totale_amount = operationes_complete.map(val => val.mercuryo).reduce((total, elt) => total + elt);
                    totale_crypto = operationes_complete.map(val => val.bch).reduce((som, elt) => som + elt);
                    fees = totale_amount * fees
                    setResult({ bch: totale_crypto  ,provide: totale_amount, fee: fees, mg:totale_crypto * 655.77  - totale_amount - fees })
                } else setResult({ bch: 0, provide: 0, fee: 0, mg: 0 })
                break;
        
            default:
                let operateur = txs.filter(val => val.status === "completed" );
                setTransactNumber(operateur.length)
                let totale_amoun = operateur.map(val => val.mercuryo).reduce((total, elt) => total + elt);
                let totale_cryptoy = operateur.map(val => val.bch).reduce((som, elt) => som + elt);
                let feey = totale_amount * 0.095
                setResult({ bch: totale_cryptoy  ,provide: totale_amoun, fee: feey, mg:totale_cryptoy * 655.77  - totale_amoun - feey })
                break;
        }

        // let ctxs=[], mercuryo=0, bch=0, intouch=0, fee=0 // completd transactions table
        // txs.forEach((tx)=>{
        //     if(tx.status==='completed') {
        //         ctxs.push(tx)
        //         mercuryo+=tx.mercuryo
        //         bch+=tx.bch
        //         intouch+=tx.amount
        //         fee+=250
        //     }
        // })
        // if(ctxs.length) {
        //     // console.log("continue")
        //     setResult({...result, mercuryo, bch, intouch, fee, mg: (bch*655-intouch-fee)})
        // }
        // else  setResult({ mercuryo: 0, bch: 0, intouch: 0, fee:0, mg: 0 })

    }
    const setPeriod=(period)=>{
        setLoading(true)
        getTransactions(period).then(()=>{
            // setResult()
            setLoading(false)
        })
    }
    console.log("le provider du send", provider)
    console.log("the transactions du resultat", result)
	return (
    <div className="send">
      <div className="asses">
        <h1>Bilan du transfert</h1>
        <div className="assests">
          {/* <div className="assest">
                                <div className="amount"  style={{backgroundColor: '#5735fe'}}>
                                    <h2>{round(2, result.mercuryo)}&euro;</h2>
                                    <h4>{round(2, result.mercuryo*655)} XAF</h4>
                                </div>
                                <p>de payements effectues chez Mercuryo</p>
                            </div> */}

          {!loading ? (
            <>
              <div className="assest">
                <div className="amount" style={{ backgroundColor: "#3bb28e" }}>
                  <h2>{round(2, result.bch)}&euro;</h2>
                  <h4>{round(2, result.bch * 655)} XAF</h4>
                </div>
                <p>De cryptos encaissees par Ipercash</p>
              </div>
            </>
          ) : (
            <Skel color="success.main" />
          )}

          {!loading ? (
            <>
              <div className="assest">
                <div className="amount" style={{ backgroundColor: "#cc1616" }}>
                  <h2>{round(2, result.provide / 655)}&euro;</h2>
                  <h4>{round(2, result.provide)} XAF</h4>
                </div>
                <p>De transferts effectues par {provider}</p>
              </div>
            </>
          ) : (
            <Skel color="error.main" />
          )}
          { !loading ? (<><div className="assest">
            <div className="amount" style={{ backgroundColor: "#038cf4" }}>
              <h2>{round(2, result.fee / 655.77)}&euro;</h2>
              <h4>{round(2,result.fee)} XAF</h4>
            </div>
            <p>De frais ipercash + mercuryo</p>
          </div></>) : <Skel color="primary.main" />}
          
        </div>
        <center>
          <h3>
            {" "}
            {transactNumber} reussies sur {transactions.txs.length} Transactions
            initiees
          </h3>
        </center>
        <div className="bar"></div>
        <Marge mg={result.mg} />
      </div>
    </div>
  );
}


const Marge=({mg})=>{
    return (
        <div className="bilan">
            <div className="">La marge realisee est de : </div>
            <div className=""> {round(2, mg/655.77)} &euro; / {round(2, mg)} XAF </div>
        </div>
    )
}

const Skel = ({color}) => {
    return (
        <div >
            <Skeleton sx={{ bgcolor: color}} variant="circular" width={125} height={125} />
            <span className='mar'></span>
            <Skeleton variant="text"  sx={{ fontSize: '1rem' }} />
        </div>
    )
}

export default SendBilan