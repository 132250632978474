import React, { useState, useEffect } from 'react'
import Skeleton from '@mui/material/Skeleton'

import "./bilan.css"
import SellBilan from './SellBilan'
import BuyBilan from './BuyBilan'
import SendBilan from './SendBilan'


function Bilan({show}) {
  const [state, setState] = useState("wenguii")

  return (
      <div className="bilans">
          <div>
            <h1>Bilans des services</h1>
            <div className='provid'>Marchand: <Period state={state} setState={setState}  /></div> 
          </div>
          {show==true ? <BuyBilan provider={state} /> : show === "buy" ? <BuyBilan provider={state} /> : ""}
          {show==true ? <SellBilan provider={state} /> : show === "sell" ? <SellBilan provider={state} /> : ""}
          {show==true ? <SendBilan provider={state} /> : show === "send" ? <SendBilan provider={state} /> : ""}
      </div>
  )
}

const Period = ({ change, state, setState }) => {
  
  const ref = React.createRef()

  const toogleList = () => ref.current.classList.toggle('show')
  const selectProvider = (label) => {
      setTimeout(() => setState(label), 500)
      // change()
      toogleList()
  }

  
  // console.log("les props ", time)

  return (
      <div className="choose-time">
          <div className="dropdown" onClick={toogleList} > <h4>{state}</h4> <span /> </div>
          <div ref={ref} className="countries-list">
              <div className="time" onClick={() => selectProvider("wenguii",)}  >
                  <h4>Wenguii</h4>
              </div>
              <div className="time" onClick={() => selectProvider("intouch",)} >
                  <h4>Intouch</h4>
              </div>
              <div className="time" onClick={() => selectProvider("cinetpay", )} >
                  <h4>Cinetpay</h4>
              </div>
              <div className="time" onClick={() => selectProvider("All", )} >
                  <h4>Toutes</h4>
              </div>
          </div>
      </div>
  )
}

export default Bilan
